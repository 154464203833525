/* eslint eqeqeq: "off" */
import React, { useState, useCallback, useEffect } from "react"
import Layout, { isLoggedIn } from "../ui/Layout"
import connectStore from "../state/connectStore"
import ClientSideQrReader from "../ui/LoadableClientSideQrReader"
import { navigate } from "gatsby"
import { fromUrlToOrderId, fetchOrder, createOrderId } from "../util/OrderUtil"
import {
  Divider,
  Header,
  Input,
  Button,
  Modal,
  Icon,
} from "semantic-ui-react"
import Color from "../style/Color"

const pStyle = { fontSize: "1.25em" };

const onError = e => {
  alert(e);
};

const Page = ({ location, resetOrder, setOrder }) => {
  const [invalidMessage, setInvalidMessage] = useState(null);
  const [textId, setTextId] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    isLoggedIn().then(setLoggedIn);
  }, [setLoggedIn]);

  const onScan = useCallback(url => {
    const orderId = fromUrlToOrderId(url);
    if(orderId != null) {
      fetchOrder({
        orderId,
        onSuccess: order => {
          setOrder(order);
          navigate("/order-cookie");
        },
        onFailure: () => {
          setInvalidMessage(
            "We could not find an order from the scanned QR code. Please scan another QR code or enter a new ID."
          );
        },
      });
    }
  }, [setOrder]);

  const onStartOrder = useCallback(() => {
    fetchOrder({
      orderId: textId,
      onSuccess: order => {
        setOrder(order);
        navigate("/order-cookie");
      },
      onFailure: () => {
        setInvalidMessage(
          textId.length == 0 ?
          "Please enter an ID."
          :
          "We could not find an order with ID \"" + textId + ".\" Please scan another QR code or enter a new ID."
        );
      },
    });
  }, [textId, setOrder]);

  return (
    <Layout
      location={location}
      validate={false}
      showBack={location.state != null && location.state.showBack}
      to="/order-cookie"
      header={<><Icon name="qrcode"/>Please scan a QR code</>}
    >
      <ClientSideQrReader
        onScan={onScan}
        onError={onError}
      />
      <Divider horizontal>
        <Header as='h4'>
          Or
        </Header>
      </Divider>
      <Header>Enter an order ID</Header>
      <Input
        placeholder="Order ID"
        value={textId}
        onChange={(e, { value }) => setTextId(value)}
        onKeyPress={({ key }) => key == "Enter" && onStartOrder()}
        action={<Button
          content="Ok"
          onClick={onStartOrder}
          color={Color.accent}
          icon="angle right"
          labelPosition="right"
        />}
      />
      { loggedIn &&
        <>
          <Divider hidden/>
          <Button color="orange" onClick={() => {
            createOrderId(orderId => {
              fetchOrder({
                orderId,
                onSuccess: order => {
                  setOrder(order);
                  navigate("/order-cookie");
                },
                onFailure: () => {
                  setInvalidMessage("We could not create a free order.");
                },
              });
            });
          }}>Start Free Order</Button>
        </>
      }
      <Divider hidden/>
      <Divider hidden/>
      <Modal
        open={invalidMessage != null}
        size="small"
        dimmer="blurring"
      >
        <Modal.Content>
          <Header>Order ID Not Found</Header>
          <p style={pStyle}>{invalidMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color={Color.accent} onClick={() => setInvalidMessage(null)}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </Layout>
  );
};

export default connectStore(Page);
